const login = () => {
    // code for post sign-in actions
};

const logout = () => {
    return Promise.resolve('Logged Out Successfully');
};

const isAuthenticated = () => {
    if (localStorage.getItem('isLoggedIn')){
        return true;
    } else {
        return false;
    }
};

export {login, logout, isAuthenticated};
