import React from "react";
import { Card, Form, Input, Select, PageHeader, Button } from "antd";
import "antd/dist/antd.css";

const { Option } = Select;

const InboxForm = () => {
  const onFinish = (values) => {
    console.log(values);
  };

  const onTagChange = () => {
    console.log("Tag Changed");
  };

  return (
        <Card>
          <PageHeader className="site-page-header" title="Inbox Form" />
      
          <Form layout="vertical" name="control-hooks" onFinish={onFinish}>
            <Form.Item name="note" label="To" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="tag" label="Tag" rules={[{ required: true }]}>
              <Select
                placeholder="Select a option and change input text above"
                onChange={onTagChange}
                allowClear
              >
                <Option value="tag1">Tag 1</Option>
                <Option value="tag2">Tag 2</Option>
                <Option value="tag3">Tag 3</Option>
              </Select>
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 0,
                span: 16,
              }}
              name="submit"
            >
              <Button type="primary">Submit</Button>
            </Form.Item>
          </Form>

        </Card>
    
  );
};

export default InboxForm;
