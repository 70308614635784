import { notification } from 'antd';

// type NotificationType = 'success' | 'info' | 'warning' | 'error'; Possible values

const toaster = (type, message, description='') => {
  notification[type]({
    message: message,
    description: description,
  });
};

export default toaster;
