import ACTIONS from "../actions";

const userReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.USER_LOGGED_IN:
            return {...state, userDetails: action.payload}
        case ACTIONS.USER_LOGGED_OUT:
            return {...state, userDetails: null}
        default:
            return {...state}
    }
};

export default userReducer;
