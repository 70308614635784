import { InboxOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import React, { useState, useEffect, useReducer, useRef } from "react";
import { Route, Routes } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./App.css";
import "antd/dist/antd.css";
import Login from "./components/authentication/Login";
import {isAuthenticated, logout} from './services/authenticationService'
import {LogoutOutlined} from '@ant-design/icons';
import toaster from "./services/toastMessageService";
import InboxForm from "./components/inbox/InboxForm";
import userReducer from "./reducers/userReducer";
import IncentivesAndTimeGating from "./components/incentives-timegating/incentives-timegating";

const { Header, Content, Sider } = Layout;

export const AppContext = React.createContext();

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  // getItem("User", "sub1", <UserOutlined />, [
  //   getItem("Tom", "3"),
  //   getItem("Bill", "4"),
  //   getItem("Alex", "5"),
  // ]),
  getItem("Inbox Form", "inbox", <InboxOutlined />),
  getItem("Incentives And TimeGating", "incentives-time-gating", <InboxOutlined />),

];

const App = () => {

  const [store, dispatch] = useReducer(userReducer, null);
  let history = useNavigate();

  const backgroundColorDivRef = useRef();

  useEffect(() => {
    if (!isAuthenticated()) history('/login');


    if (window.location.pathname === '/login') {
      backgroundColorDivRef.current.classList.remove('site-layout-background');
    }
  }, []);

  const menuItemClicked = (e) => {
    history("/" + e.key);
  };

  const [collapsed, setCollapsed] = useState(false);

  const logoutUser = () => {
    logout().then(res=> {
      toaster("success", "Logged Out Successfully")
      localStorage.clear();
      history('/login');
      window.location.reload();
    }).catch(err=> console.log(err));
  };

  return (
    <AppContext.Provider value={{store, dispatchAction: dispatch}}>
    <Layout
    style={{
      height: "100vh",
    }}
    >
      {
        isAuthenticated() && <Sider
        breakpoint="lg"
      collapsedWidth="0"
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className="logo" />
        <Menu
          theme="dark"
          defaultSelectedKeys={["1"]}
          mode="inline"
          items={items}
          onClick={(e) => menuItemClicked(e)}
        />
      </Sider>
      }
      
      <Layout className="site-layout">
        {
          isAuthenticated() && <Header
          className="site-layout-background"
          style={{
            padding: 0,
          }}
        >
          <div className="header-content">
            <div className="logo">
            JobFinder Admin
            </div>
            <div className="header-menu">
              <span onClick={logoutUser}><LogoutOutlined /></span>
            </div>
          </div>
        </Header>
        }
        
        <Content
          style={{
            margin: '24px 16px 0',
            paddingTop: "15px",
          }}
        >
          <div
          ref={backgroundColorDivRef}
          className="site-layout-background"
            style={{
              padding: 24,
              minHeight: 360,
            }}
          >
            <Routes>
              <Route path="/" exact element={<Login />} />
              <Route path="/inbox" exact element={<InboxForm />} />
              <Route path="/login" element={<Login />} />
              <Route path="/incentives-time-gating" element={<IncentivesAndTimeGating />} />  
            </Routes>
          </div>
        </Content>
      </Layout>
    </Layout>
    </AppContext.Provider>
  );
};

export default App;
