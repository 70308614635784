import React from "react";
import { Card, Form, Input, Select, PageHeader, Button } from "antd";
import "antd/dist/antd.css";
import './incentives-timegating.css';

const { Option } = Select;

const IncentivesAndTimeGating = () => {

  return (
    <div className="container">
      <iframe className="responsive-iframe" src={process.env.REACT_APP_INCENTIVE_TIMEGATING_URL}></iframe>
    </div>    
  );
};

export default IncentivesAndTimeGating;
