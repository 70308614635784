import { Space, Typography, Image} from "antd";
import React, {useEffect, useState, useContext} from "react";
import { login } from "../../services/authenticationService";
import { useNavigate } from "react-router-dom";
import toaster from "../../services/toastMessageService";
import jwt_decode from 'jwt-decode';
import { AppContext } from "../../App";
import ACTIONS from "../../actions";

const { Title } = Typography;

const Login = () => {
  const {store,dispatchAction} = useContext(AppContext);

  let history = useNavigate();

  const handleGoogleSignIn = (values) => {
    const userObj = jwt_decode(values.credential);
    dispatchAction({type: ACTIONS.USER_LOGGED_IN, payload: userObj});
    localStorage.setItem('isLoggedIn',"true");
    toaster("success", "Login Successful");
    history("/inbox");
  };

  const onFinishFailed = (errorInfo) => {console.log(errorInfo)};

  useEffect(()=> {

    if(localStorage.getItem('isLoggedIn')) history("/inbox");

    setTimeout(() => {
      window.google.accounts.id.initialize({
        client_id: '387034457055-rjkgstmglftbnu5e4gikcm8vsktqd6j7.apps.googleusercontent.com',
        callback: handleGoogleSignIn,
      });
  
      window.google.accounts.id.renderButton(
        document.getElementById('signInButton'),
        {theme:"outline"}
      );
    }, 1000);
    
  }, []);

  return (
    <>
      <div className="login-form" style={{display:'flex',justifyContent:'center',alignItems:'center',paddingTop:'150px'}}>
          <Space direction="vertical" size={40} align="center">

            <Space direction="horizontal" value={0} align="center">
              {/* <img src="/images/vahan_icon.svg" alt="Vahan Icon"></img> */}
              <Image
                width={40}
                height={40}
                preview={{
                  visible:false
                }}
                src="/images/vahan_icon.svg"
              />
              <Title level={3}>Login to Admin Portal</Title>
            </Space>

            <div id="signInButton"></div>
          </Space>
      </div>
    </>
  );
};

export default Login;
